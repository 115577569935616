import dayjs from "dayjs";
const painLineChart = {
  colors: ["var(--theme-color,#2c7be5)", "#000"],
  series: [
    {
      name: "Client Answer",
      data: [],
    },
    {
      name: "Provider Observation",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "line",
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    dropShadow: {
      enabled: true,
      color: ["var(--theme-color,#2c7be5)", "#000"],
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: true,
  },
  yaxis: {
    min: 0,
    max: 10,
    title: {
      text: "Pain Rate",
    },
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: function (value) {
        if (value) {
          let newValue = value.split(" - ");
          return dayjs(newValue[0]).format("MMM-DD hh:mm A");
        }
        return "";
      },
      rotate: -45,
      hideOverlappingLabels: true,
    },
    categories: [],
    tickPlacement: "on",
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let bodyLocations = [];
      let activityCategories = [];
      let result = ``;
      if (
        (series?.[0]?.[dataPointIndex] ||
          series?.[0]?.[dataPointIndex] === 0) &&
        (series?.[1]?.[dataPointIndex] || series?.[1]?.[dataPointIndex] === 0)
      ) {
        bodyLocations =
          w.config.series[0].data[dataPointIndex].u.body_locations.join(", ");
        activityCategories =
          w.config.series[0].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        const length =
          w.config.series[0].data[dataPointIndex].u.body_locations.length;
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[seriesIndex].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[0]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Client Answer: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[0][dataPointIndex]
                        } (${PAIN_LEVELS[series[0][dataPointIndex]]})</span>
                        </div>
                    </div>
                </div>
                 <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[1]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Provider Observation: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[1][dataPointIndex]
                        } (${PAIN_LEVELS[series[1][dataPointIndex]]})</span>
                        </div>
                    </div>
                </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Location${
                      length > 0 ? "s" : ""
                    } of pain: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${bodyLocations}</span>
                </div>
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Pain Time: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      w.config.series[seriesIndex].data[dataPointIndex].u
                        .pain_time
                    }</span>
                </div>
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
            </div>
            `;
      } else if (
        series?.[0]?.[dataPointIndex] ||
        series?.[0]?.[dataPointIndex] === 0
      ) {
        bodyLocations =
          w.config.series[0].data[dataPointIndex].u.body_locations.join(", ");
        activityCategories =
          w.config.series[0].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        const length =
          w.config.series[0].data[dataPointIndex].u.body_locations.length;
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[0].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: ${
                  w.config.colors[0]
                };"></span>
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Client Answer: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      series[0][dataPointIndex]
                    } (${PAIN_LEVELS[series[0][dataPointIndex]]})</span>
                    </div>
                </div>
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Location${
                      length > 0 ? "s" : ""
                    } of pain: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${bodyLocations}</span>
                </div>
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Pain Time: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      w.config.series[0].data[dataPointIndex].u.pain_time
                    }</span>
                </div>
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
            </div>
            `;
      } else {
        bodyLocations =
          w.config.series[1].data[dataPointIndex].u.body_locations.join(", ");
        activityCategories =
          w.config.series[1].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        const length =
          w.config.series[1].data[dataPointIndex].u.body_locations.length;
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[1].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: ${
                  w.config.colors[1]
                };"></span>
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Provider Observation: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      series[1][dataPointIndex]
                    } (${PAIN_LEVELS[series[1][dataPointIndex]]})</span>
                    </div>
                </div>
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Location${
                      length > 0 ? "s" : ""
                    } of pain: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${bodyLocations}</span>
                </div>
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Pain Time: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      w.config.series[1].data[dataPointIndex].u.pain_time
                    }</span>
                </div>
            </div>
             <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
            </div>
            `;
      }

      return result || "";
    },
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
};
const fatigueLineChart = {
  colors: ["var(--theme-color,#2c7be5)", "#000"],
  series: [
    {
      name: "Client Answer",
      data: [],
    },
    {
      name: "Provider Observation",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "line",
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    dropShadow: {
      enabled: true,
      color: ["var(--theme-color,#2c7be5)", "#000"],
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: true,
  },
  yaxis: {
    min: 0,
    max: 10,
    title: {
      text: "Fatigue Rate",
    },
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: function (value) {
        if (value) {
          let newValue = value.split(" - ");
          return dayjs(newValue[0]).format("MMM-DD hh:mm A");
        }
        return "";
      },
      rotate: -45,
      hideOverlappingLabels: true,
    },
    categories: [],
    tickPlacement: "on",
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let result = ``;
      let activityCategories = [];
      if (
        (series?.[0]?.[dataPointIndex] ||
          series?.[0]?.[dataPointIndex] === 0) &&
        (series?.[1]?.[dataPointIndex] || series?.[1]?.[dataPointIndex] === 0)
      ) {
        activityCategories =
          w.config.series[0].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[seriesIndex].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[0]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Client Answer: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[0][dataPointIndex]
                        } (${FATIGUE_LEVELS[series[0][dataPointIndex]]})</span>
                        </div>
                    </div>
                </div>
                 <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[1]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Provider Observation: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[1][dataPointIndex]
                        } (${FATIGUE_LEVELS[series[1][dataPointIndex]]})</span>
                        </div>
                    </div>
                </div>
              <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
            `;
      } else if (
        series?.[0]?.[dataPointIndex] ||
        series?.[0]?.[dataPointIndex] === 0
      ) {
        activityCategories =
          w.config.series[0].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[0].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: ${
                  w.config.colors[0]
                };"></span>
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Client Answer: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      series[0][dataPointIndex]
                    } (${FATIGUE_LEVELS[series[0][dataPointIndex]]})</span>
                    </div>
                </div>
                </div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
            `;
      } else {
        activityCategories =
          w.config.series[1].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[1].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: ${
                  w.config.colors[1]
                };"></span>
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Provider Observation: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      series[1][dataPointIndex]
                    } (${PAIN_LEVELS[series[1][dataPointIndex]]})</span>
                    </div>
                </div>
                </div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
            `;
      }

      return result || "";
    },
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
};
const moodLineChart = {
  colors: ["var(--theme-color,#2c7be5)", "#000"],
  series: [
    {
      name: "Client Answer",
      data: [],
    },
    {
      name: "Provider Observation",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "line",
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    dropShadow: {
      enabled: true,
      color: ["var(--theme-color,#2c7be5)", "#000"],
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: true,
  },
  yaxis: {
    min: 0,
    max: 10,
    title: {
      text: "Mood Rate",
    },
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: function (value) {
        if (value) {
          let newValue = value.split(" - ");
          return dayjs(newValue[0]).format("MMM-DD hh:mm A");
        }
        return "";
      },
      rotate: -45,
      hideOverlappingLabels: true,
    },
    categories: [],
    tickPlacement: "on",
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let activityCategories = [];
      let result = ``;
      if (
        (series?.[0]?.[dataPointIndex] ||
          series?.[0]?.[dataPointIndex] === 0) &&
        (series?.[1]?.[dataPointIndex] || series?.[1]?.[dataPointIndex] === 0)
      ) {
        activityCategories =
          w.config.series[0].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[seriesIndex].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[0]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Client Answer: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[0][dataPointIndex]
                        } (${MOOD_LEVELS[series[0][dataPointIndex]]})</span>
                        </div>
                    </div>
                </div>
                 <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[1]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Provider Observation: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[1][dataPointIndex]
                        } (${MOOD_LEVELS[series[1][dataPointIndex]]})</span>
                        </div>
                    </div>
                </div>
                 <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
            `;
      } else if (
        series?.[0]?.[dataPointIndex] ||
        series?.[0]?.[dataPointIndex] === 0
      ) {
        activityCategories =
          w.config.series[0].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[0].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: ${
                  w.config.colors[0]
                };"></span>
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Client Answer: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      series[0][dataPointIndex]
                    } (${MOOD_LEVELS[series[0][dataPointIndex]]})</span>
                    </div>
                </div>
            </div>
             <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
            `;
      } else {
        activityCategories =
          w.config.series[1].data[dataPointIndex].u.activity_categories.join(
            ", "
          );
        result = `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[1].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <span class="apexcharts-tooltip-marker" style="background-color: ${
                  w.config.colors[1]
                };"></span>
                <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                    <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Provider Observation: </span>
                    <span class="apexcharts-tooltip-text-y-value">${
                      series[1][dataPointIndex]
                    } (${MOOD_LEVELS[series[1][dataPointIndex]]})</span>
                    </div>
                </div>
            </div>
             <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
            `;
      }

      return result || "";
    },
  },
  fill: {
    opacity: 1,
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
};
const sleepBarChart = {
  colors: ["var(--theme-color,#2c7be5)"],
  series: [
    {
      name: "Sleep hours",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: function (value) {
        if (value) {
          let newValue = value.split(" - ") || "";
          if (newValue.length > 0)
            return dayjs(newValue[0]).format("MMM-DD hh:mm A");
        }
        return "";
      },
      rotate: -45,
      hideOverlappingLabels: true,
    },
    categories: [],
    tickPlacement: "on",
  },
  yaxis: {
    min: 0,
    max: 24,
    title: {
      text: "Hours",
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let activityCategories = [];
      activityCategories =
        w.config.series[0].data[dataPointIndex].u.activity_categories.join(
          ", "
        );
      return `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[seriesIndex].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[0]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Sleep hours: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[0][dataPointIndex]
                        }h</span>
                        </div>
                    </div>
                </div>
                 <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
            `;
    },
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
};
const sleepBarChartHorizontal = {
  colors: ["var(--theme-color,#2c7be5)"],
  series: [
    {
      name: "Sleep Hours",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: true,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  grid: {
    borderColor: "transparent",
  },
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  yaxis: {
    type: "category",
    labels: {
      formatter: function (value) {
        if (value) {
          let newValue =
            typeof value === "string" ? value.split(" - ") || "" : "";
          if (newValue.length > 0)
            return dayjs(newValue[0]).format("MMM-DD hh:mm A");
        }
        return "";
      },
    },
    categories: [],
    tickPlacement: "on",
  },
  xaxis: {
    min: 0,
    max: 24,
    title: {
      text: "Hours",
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let activityCategories = [];
      activityCategories =
        w.config.series[0].data[dataPointIndex].u.activity_categories.join(
          ", "
        );
      return `
                <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
                  w.config.series[seriesIndex].data[dataPointIndex].x
                }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[0]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Sleep hours: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[0][dataPointIndex]
                        }h</span>
                        </div>
                    </div>
                </div>
                 <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
            `;
    },
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
};
const headachesAreaChart = {
  colors: ["var(--theme-color,#2c7be5)"],
  series: [
    {
      name: "Headaches Rate",
      data: [],
    },
  ],
  chart: {
    type: "area",
    stacked: false,
    height: 350,
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
    zoom: {
      type: "x",
      enabled: true,
      autoScaleYaxis: true,
    },
    toolbar: {
      autoSelected: "zoom",
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    colors: ["var(--theme-color,#2c7be5)"],
    strokeColor: "var(--theme-color,#2c7be5)",
  },
  stroke: {
    curve: "straight",
  },
  fill: {
    type: "gradient",
    colors: ["var(--theme-color,#2c7be5)"],
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.7,
      opacityTo: 0.3,
      stops: [0, 90, 100],
    },
  },
  yaxis: {
    min: 0,
    max: 10,
    title: {
      text: "Headaches Rate",
    },
  },
  xaxis: {
    type: "category",
    labels: {
      formatter: function (value) {
        if (value) {
          let newValue = value.split(" - ");
          return dayjs(newValue[0]).format("MMM-DD hh:mm A");
        }
        return "";
      },
      rotate: -45,
      hideOverlappingLabels: true,
    },
    categories: [],
    tickPlacement: "on",
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      let activityCategories = [];
      activityCategories =
        w.config.series[0].data[dataPointIndex].u.activity_categories.join(
          ", "
        );
      return `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${
        w.config.series[seriesIndex].data[dataPointIndex].x
      }</div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                    <span class="apexcharts-tooltip-marker" style="background-color: ${
                      w.config.colors[0]
                    };"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                        <div class="apexcharts-tooltip-y-group" style="padding: 1px 0px !important;">
                        <span class="apexcharts-tooltip-text-y-label">Headaches Rate: </span>
                        <span class="apexcharts-tooltip-text-y-value">${
                          series[seriesIndex][dataPointIndex]
                        } (${
        HEADACHE_LEVELS[series[seriesIndex][dataPointIndex]]
      })</span>
                        </div>
                    </div>
                </div>
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                <div class="apexcharts-tooltip-y-group" style="display: flex;padding: 1px 0px !important;">
                    <span class="apexcharts-tooltip-text-y-label">Activity Categories: </span>
                    <span class="apexcharts-tooltip-text-y-value" style="white-space: break-spaces !important;max-width: 300px !important;">${
                      activityCategories.length
                        ? activityCategories
                        : "Not specified"
                    }</span>
                </div>
               </div>
                `;
    },
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
};
const generalSymptomsObservedBarChart = {
  colors: ["var(--theme-color,#2c7be5)"],
  series: [
    {
      name: "Observed Percentage",
      data: [],
    },
  ],
  chart: {
    height: 400,
    type: "bar",
    stacked: true,
    foreColor: "var(--main-text-color)",
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "transparent",
  },
  xaxis: {
    type: "category",
    labels: {
      rotate: -45,
      hideOverlappingLabels: true,
      minHeight: 170,
    },
    categories: [],
    tickPlacement: "on",
  },
  yaxis: {
    min: 0,
    max: 100,
    labels: {
      formatter: function (value) {
        return +parseFloat(value).toFixed(2) + "%";
      },
    },
  },
  tooltip: {
    followCursor: true,
    x: {
      formatter: function (_, opt) {
        return opt.w.config.series[0].data[opt.dataPointIndex].label;
      },
    },
    intersect: false,
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
};
const generalSymptomsObservedBarChartHorizontal = {
  colors: ["var(--theme-color,#2c7be5)"],
  series: [
    {
      name: "Observed percentage",
      data: [],
    },
  ],
  chart: {
    height: 350,
    type: "bar",
    stacked: true,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      horizontal: true,
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  grid: {
    borderColor: "transparent",
  },
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  yaxis: {
    type: "category",
    categories: [],
    tickPlacement: "on",
  },
  xaxis: {
    min: 0,
    max: 100,
    labels: {
      formatter: function (value) {
        return value + "%";
      },
    },
  },
  tooltip: {
    followCursor: true,
    intersect: false,
    x: {
      formatter: function (_, opt) {
        return opt.w.config.series[0].data[opt.dataPointIndex].label;
      },
    },
    y: {
      formatter: function (value) {
        return +parseFloat(value).toFixed(2) + "%";
      },
    },
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
};

const PAIN_LEVELS = [
  "No Pain",
  "Very Mild",
  "Discomforting",
  "Tolerable",
  "Distressing",
  "Very Distressing",
  "Intense",
  "Very Intense",
  "Horrible",
  "Unbearable",
  "Unspeakable",
  "No Answer",
];
const HEADACHE_LEVELS = [
  "No Pain",
  "Pain is very mild",
  "Minor pain",
  "Noticeable pain",
  "Moderate pain",
  "Moderately strong pain",
  "Moderately stronger pain",
  "Strong pain",
  "Very strong pain",
  "Pain that is very hard to tolerate",
  "Worst pain possible",
  "No Answer",
];
const HEADACHE_LEVEL_EMOJI = [
  "fas fa-smile-beam fa-2x text-success",
  "fas fa-smile-beam fa-2x text-success",
  "fas fa-smile fa-2x text-7ac98b",
  "fas fa-smile fa-2x text-7ac98b",
  "fas fa-meh fa-2x text-deep-warning",
  "fas fa-meh fa-2x text-deep-warning",
  "fas fa-meh fa-2x text-deep-warning",
  "fas fa-frown-open fa-2x text-danger",
  "fas fa-frown-open fa-2x text-danger",
  "fas fa-frown fa-2x text-dark-red",
  "fas fa-frown fa-2x text-dark-red",
  "fas fa-meh-blank fa-2x text-secondary",
];

const FATIGUE_LEVELS = [
  "No Fatigue",
  "Mild",
  "Mild",
  "Mild",
  "Moderate",
  "Moderate",
  "Moderate",
  "Extreme",
  "Extreme",
  "Extreme",
  "The Worst Fatigue",
  "No Answer",
];

const MOOD_LEVELS = [
  "Horrible",
  "Worst",
  "Very Bad",
  "Bad",
  "Less So-so",
  "So-so",
  "More So-so",
  "Good",
  "Very Good",
  "Great",
  "Wonderful",
  "No Answer",
];

export default {
  painLineChart,
  fatigueLineChart,
  moodLineChart,
  sleepBarChart,
  sleepBarChartHorizontal,
  headachesAreaChart,
  generalSymptomsObservedBarChart,
  generalSymptomsObservedBarChartHorizontal,
  PAIN_LEVELS,
  FATIGUE_LEVELS,
  HEADACHE_LEVEL_EMOJI,
  MOOD_LEVELS,
  HEADACHE_LEVELS,
};
